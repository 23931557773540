import { imageUrlAdapter } from '@/adapters/constructor/utils/image';
import { linkAdapter } from '@/adapters/constructor/utils/link';

export default ({ data }) => {
  return {
    name: data.name,
    linkPhone: linkAdapter(data.link_phone),
    linkEmail: linkAdapter(data.link_email),
    linkTg: linkAdapter(data.link_tg),
    title: data.title,
    contact: {
      image: imageUrlAdapter(data.contact_avatar?.image),
      name: data.contact_name,
      company: data.contact_company,
    },
    linkAddress: linkAdapter(data.link_address),
    map: imageUrlAdapter(data.map?.image),
    stations: data.stations?.map((station) => ({
      id: station.id,
      name: station.name,
      time: station.time,
      icon: station.icon,
    })),
  };
};
